
@media only screen and (orientation: landscape){
  body {
  }

  code {
  }

  iframe::before{
  }

  #root{
  }

  #sys_ve_canvas, #sys_ui_canvas, #page_ve_canvas, #page_ui_canvas {
  }
  #sys_ve_canvas {
  }
  #sys_ui_canvas {
  }
  #page_ve_canvas {
  }
  #page_ui_canvas {
  }

  /* BEGIN: PIP GUI Styling */
  .pip_gui{}
  .pip_ack{
  }
  .pip_ack:hover{
  }
  .pip_button{
  }
  .pip_text{
  }
  .pip_reply{
  }

  #root.pip_gui{
  }
  #root > .pip_gui{
  }


  /* BEGIN: PIP Splash Styling */
  /* PIP Splash screens are autonomous heads up displays which appear and disappear on their own.
     This style is useful for displaying the status of running processes, which don't require acknowlegement. */
  #root > .pip_gui.pip_splash{}

  /* BEGIN: PIP Post Styling */
  /* PIP Post screens are notification pages which require acknowlegement before moving on.
     This style allows for the system to post information which the user may wish to examine further before continuing on. */
  #root > .pip_gui.pip_post{}

  /* BEGIN: PIP Chat Styling */
  /* PIP Chat screens are forms styled as a dialogue between the system and the user.
     This style allows for decisions to be made and input to be handled. */
  #root > .pip_gui.pip_chat{
  }
  #root > .pip_gui.pip_chat .ui_side{
  }
  #root > .pip_gui.pip_chat .user_side{
  }
  #root > .pip_gui.pip_chat .pip_text{
  }
  #root > .pip_gui.pip_chat .pip_reply{
  }
  #root > .pip_gui.pip_chat .pip_button{
  }
  #root > .pip_gui.pip_chat .pip_toggle{
  }

  /* BEGIN: PIP Import Styling */
  /* PIP Import screens are externally-sourced
     This style allows for the system to post information which the user may wish to examine further before continuing on. */
  #root > .pip_gui.pip_import{
  }
  #root > .pip_gui.pip_import > iframe{
  }

  #verify_capabilities{}
  #init_controls{}

  .bizCard{
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  .bizCard .panel.comms, #sys_ui_canvas .panel.comms{
    grid-column: 1;
    grid-row: 1;
    width: 25vw;
    height: 25vw;
  }
  .bizCard .panel.hero, #sys_ui_canvas .bizCard .panel.hero{
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    width: 100vw;
    height: 100vh;
  }
  .bizCard .panel.menu, #sys_ui_canvas .bizCard .panel.menu{
    grid-column: 4;
    grid-row: 3;
    width: 25vw;
    height: 25vw;
  }

  #confirm_privileges{}
  #connect{}

}
