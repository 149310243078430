@media only screen {
  a{
    transition: all 0.2s;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: whitesmoke;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  iframe::before{
    content: 'All your base are belong to us.';
    background-color: white;
    display: grid;
    height: 100%;
    width: 100%;
  }

  .hidden{
    opacity: 0;
  }

  #root{
    position: fixed;
    z-index: 2;
    display: grid;

    transition: opacity 2s .5s;
  }



  #sys_ve_canvas, #sys_ui_canvas, #page_ve_canvas, #page_ui_canvas {
    position: fixed;
    pointer-events: none;
  }
  #sys_ve_canvas {
    z-index: 1;
  }
  #sys_ui_canvas {
    z-index: 4;
  }
  #sys_ui_canvas.onDisplay{
    perspective: none !important;
    z-index: 6 !important;
  }
  #page_ve_canvas {
    position: fixed;
    z-index: 2;
  }
  #page_ui_canvas {
    position: fixed;
    z-index: 3;
  }
  #page_ui_canvas.onDisplay{
    perspective: none !important;
    z-index: 5 !important;
  }

  /* BEGIN: PIP GUI Styling */
  .pip_gui{}
  .pip_ack{
    border-radius: 10%;
    cursor: pointer;
    background-color: #040633;
    border-color: #474B96;
    color: whitesmoke;
    transition: all 0.1s;
    width: 5rem;
    height: 5rem;
    font-size: xxx-large;
    grid-column: 3;
    grid-row: 5;
    margin-left: calc( (20vw - 5rem) / 2 );
    margin-bottom: 2.5rem
  }
  .pip_ack:hover{
    border-radius: 15%;
    background-color: #492900;
    border-color: #995D12;
  }
  .pip_button{
    background-color: red;
    border-radius: 4rem 4rem 4rem 4rem;
    pointer-events: all;
    cursor: pointer;
    height: fit-content;
  }
  .pip_text{
    height: fit-content;
    width: fit-content;
  }
  .pip_reply{
    height: fit-content;
  }

  #root.pip_gui{
    height: 100vh;
    width: 100vw;
    opacity: 1;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 5rem auto auto auto 10rem;
  }
  #root > .pip_gui{
    display: grid;
    background-color: #000000;
    background-image: radial-gradient(#1a1e6a 2px, #000000 2px);
    background-size: 40px 40px;
  }


  /* BEGIN: PIP Splash Styling */
  /* PIP Splash screens are autonomous heads up displays which appear and disappear on their own.
     This style is useful for displaying the status of running processes, which don't require acknowlegement. */
  #root > .pip_gui.pip_splash{
    grid-column: 2;
    grid-row: 2 / -2;
    transition: all 1s;
    border-radius: 0 10vh 0 10vh;
  }

  /* BEGIN: PIP Post Styling */
  /* PIP Post screens are notification pages which require acknowlegement before moving on.
     This style allows for the system to post information which the user may wish to examine further before continuing on. */
  #root > .pip_gui.pip_post{
    grid-column: 2;
    grid-row: 2 / -2;
    transition: all 1s;
    border-radius: 0 10vh 0 10vh;
  }

  /* BEGIN: PIP Chat Styling */
  /* PIP Chat screens are forms styled as a dialogue between the system and the user.
     This style allows for decisions to be made and input to be handled. */
  #root > .pip_gui.pip_chat{
    background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #000000 70vw ), repeating-linear-gradient( #000dff, #000dff ) !important;
    background-size: auto, auto !important;
    grid-template-columns: auto auto auto auto auto;
    overflow-y: auto;
    grid-column: 2;
    grid-row: 2 / -2;
  }
  #root > .pip_gui.pip_chat .ui_side{
    grid-column: 1 / 4 !important;
    border-radius: 0px 4rem 4rem 4rem;
    margin-left: 0;
    margin-right: auto;
  }
  #root > .pip_gui.pip_chat .user_side{
    grid-column: 3 / -1 !important;
    flex-direction: row-reverse;
    border-radius: 4rem 4rem 4rem 4rem;
    margin-right: 0;
    margin-left: auto;
  }
  #root > .pip_gui.pip_chat .pip_text{
    border: none;
    background: linear-gradient( 90deg, #ffffff42, #ffffff00 );
    min-width: 11rem;
    padding: 1rem;
    font-size: x-large;
    text-align: center;
  }
  #root > .pip_gui.pip_chat .pip_reply{
    border: none;
    background: linear-gradient( 90deg, #ffffff42, #ffffff00 );
    min-width: 11rem;
    font-size: x-large;
    text-align: center;
    padding: 1rem;
    background-color: whitesmoke;
  }
  #root > .pip_gui.pip_chat .pip_button{
    width: fit-content;
    font-size: x-large;
    padding: 1rem;
  }
  #root > .pip_gui.pip_chat .pip_toggle{
    grid-row: 3;
    grid-column: 3;
    width: 4rem;
    height: 4rem;
  }

  /* BEGIN: PIP Import Styling */
  /* PIP Import screens are externally-sourced
     This style allows for the system to post information which the user may wish to examine further before continuing on. */
  #root > .pip_gui.pip_import{
    grid-row: 2 / -1;
    grid-column: 2;
    display: grid;
    grid-template-rows: auto 5rem auto 5rem;
  }
  #root > .pip_gui.pip_import > iframe{
    border-radius: 0 10vh 0 10vh;
    grid-row: 2 / -1;
    height: calc( 100vh - 5rem);
    width: 100%;
  }

  #verify_capabilities{
    grid-template-columns: 1fr 3fr 1fr;
  }
  #verify_capabilities .fps_display,
  #verify_capabilities .success,
  #verify_capabilities .failure{
    width: 100%;
    text-align: center;
    grid-column: 2;
  }
  #verify_capabilities .title{
    grid-row: 1;
    grid-column: 1 / -1;
  }
  #verify_capabilities .description{
    font-size: 1.5rem;
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;
    grid-row: 2;
    grid-column: 2;
    color: #995D12;
  }
  #verify_capabilities .fps_display{
    font-size: 2rem;
    grid-row: 3;
    color: #464A96;
  }
  #verify_capabilities .success{
    font-size: 3rem;
    color: green;
    grid-row: 4;
    grid-column: 2;
  }
  #verify_capabilities .failure{
    font-size: 3rem;
    color: goldenrod;
    grid-row: 4;
    grid-column: 2;
  }

  #init_controls{}

  .css-gui-odykkp{text-decoration-line:none;background-color:#000000;border-radius:16px;-webkit-transition:all 350ms 0ms cubic-bezier(0, 0, 1, 1);transition:all 350ms 0ms cubic-bezier(0, 0, 1, 1);overflow:hidden;display:block;color:#ffffff;}
  .css-gui-odykkp:hover{-webkit-filter:grayscale(0%);filter:grayscale(0%);}
  .css-gui-cxbi66{max-width:100%;display:block;width:100%;}
  .css-gui-tl6zyz{padding:32px;}
  .css-gui-12rqme7{font-size:0.75rem;color:currentcolor;}
  .css-gui-196s729{line-height:1.25;font-family:Inter;text-align:left;font-size:2rem;margin:0px;}
  .css-gui-1bxrphh{max-width:80ch;margin:0;color:#ffffff;}

  .bizCard{
    display: grid;
    width: 100vw;
    height: 100vh;
  }
  .bizCard .panel.comms{
    background-color: goldenrod;
    z-index: 1;
  }
  .bizCard .panel.hero{
    z-index: 0;
    display: grid;
  }
  .bizCard .panel.hero *{
    margin: auto;
    max-height: 85%;
  }

  .bizCard .panel.menu{
    z-index: 1;
    background-color: goldenrod;
  }

  #confirm_privileges{}
  #connect{}



}
